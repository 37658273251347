<template>
  <LoggedOutLayout>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="col-end-4 elevation-12 login-form"
    >
      <v-card>
        <v-card-title class="login-card-title">
          <img src="@/assets/images/logo-wittel.png" alt="" class="logo" />
          <h2>
            Inovação que<br />
            conecta e<br />
            transforma
          </h2>
        </v-card-title>
        <v-card-text>
          <div class="row">
            <div class="col-12" v-if="loggedOutByTokenError && !message">
              <v-alert class="bg-red-500 text-white mb-0">
                <strong
                  >Sua sessão expirou. Insira seu login e senha para acessar o
                  portal novamente!</strong
                >
              </v-alert>
            </div>

            <div class="col-12" v-if="message">
              <v-alert type="error" class="mt-6 text-white bg-red-600">
                {{ message }}
              </v-alert>
            </div>

            <div class="col-12">
              <label for="user">Usuário</label>
              <v-text-field
                id="user"
                v-model="user.username"
                :rules="usernameRules"
                placeholder="Insira seu usuário"
                required
                flat
                outlined
              ></v-text-field>
            </div>
            <div class="col-12">
              <label for="password">Senha</label>
              <v-text-field
                id="password"
                v-model="user.password"
                :rules="passwordRules"
                placeholder="Insira sua senha"
                name="password"
                required
                flat
                outlined
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
                @keyup.enter="login()"
              ></v-text-field>
            </div>
            <div class="col-12">
              <v-btn
                block
                v-if="!loading"
                :disabled="!valid"
                @click="login()"
                type="button"
                class="text-white bg-red-900"
                large
              >
                Entrar
              </v-btn>
              <v-btn
                v-if="loading"
                block
                type="button"
                class="text-white bg-red-900"
                loading
                large
              >
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-form>
  </LoggedOutLayout>
</template>

<script>
import LoggedOutLayout from "@/layouts/LoggedOut.vue";

export default {
  name: "Login",
  components: {
    LoggedOutLayout,
  },
  props: {
    source: String,
  },
  data() {
    return {
      valid: false,
      showPassword: false,
      loading: false,
      message: "",
      user: {
        username: "",
        password: "",
      },
      usernameRules: [
        (v) => !!v || "O nome de usuário é requerido",
        (v) =>
          v.length <= 100 ||
          "O nome de usuário deve ter menos de 100 caracteres",
      ],
      passwordRules: [(v) => !!v || "A senha é requerida"],
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    loggedOutByTokenError() {
      return sessionStorage.getItem("redirectPath");
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/home").catch(() => {});
    }
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            const redirectPath = sessionStorage.getItem("redirectPath");
            if (redirectPath) {
              this.$router
                .push(redirectPath)
                .then(() => {
                  sessionStorage.removeItem("redirectPath");
                })
                .catch(() => {});
            } else {
              this.$router.push("/home").catch(() => {});
            }
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.response.data.detail ||
              error.toString();
            setTimeout(() => {
              this.resetForm();
            }, 1000);
          }
        );
      }
    },
    resetForm() {
      this.loading = false;
      this.message = "";
      this.user.username = "";
      this.user.password = "";
      // this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
.main-wrapper {
  padding: 0;
}
.login-app {
  background: url("~@/assets/images/bg-login.jpg") no-repeat left center #680a18;
  background-size: cover;
  width: 100%;
}
.logo {
  max-width: 150px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.login-card-title {
  text-align: center;
  justify-content: center;
}

h2 {
  word-break: break-word;
  font-size: 17px;
  line-height: 1;
  font-family: "Merriweather", sans-serif;
}

@media screen and (min-width: 768px) {
  .login-card-title {
    flex-wrap: nowrap;
    text-align: left;
  }
}
@media screen and (min-width: 1247px) {
  .login-app {
    background-size: contain;
  }
}
</style>

