<template>
  <div class="h-full px-8 py-20 bg-gray-100 logged-in-page">
    <div class="p-6 container-fluid">
      <h2 class="mb-8">Gerenciar modelos de sentimento</h2>
      <v-card elevation="2">
        <v-card-title class="border-b border-gray-100 px-6 h-16">
          Modelo de Sentimento
          <v-spacer></v-spacer>
          <v-btn
            class="btn-secondary mr-3"
            small
            @click="goToSentimentModel()"
            :disabled="disabled"
            v-if="!iframe"
          >
            Analisar termo
          </v-btn>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="btn-primary" small v-bind="attrs" v-on="on">
                Novo Termo
              </v-btn>
            </template>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-card>
                <v-card-title class="border-b border-gray-100 px-6 h-16">
                  <h5>Novo Termo</h5>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="addRating"
                    class="btn-primary"
                    small
                    :disabled="disableAddRating"
                    >nova avaliação
                  </v-btn>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <label for="newTerm">Termo</label>
                        <v-text-field
                          id="newTerm"
                          v-model="newResource.word"
                          :rules="newResourceWordRules"
                          placeholder="Adicione o novo termo"
                          flat
                          outlined
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-for="(rating, i) in newResource.ratings" :key="i">
                      <v-col cols="11">
                        <v-slider
                          v-model="newResource.ratings[i]"
                          :hint="returnHintByIndex(i)"
                          max="4"
                          min="-4"
                          persistent-hint
                          required
                          flat
                        ></v-slider>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="1">
                        <v-icon small @click="removeRating(i)">
                          mdi-delete
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions class="px-5 py-3 border-t border-gray-100">
                  <v-spacer></v-spacer>
                  <v-btn
                    class="btn-secondary"
                    text
                    @click="close"
                    :disabled="loading"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    class="btn-primary"
                    text
                    @click="save"
                    :loading="loading"
                    :disabled="disableCreateWord"
                  >
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-card-title>

        <v-card-text>
          <v-alert v-if="message" type="error" dismissible>
            {{ message }}
          </v-alert>

          <v-data-table
            :headers="headers"
            :items="words"
            sort-by="id"
            :loading="loading"
            class="elevation-1"
          >
            <template v-slot:[`item.measure`]="{ item }">
              <v-chip>
                {{ formatNumber(item.measure) }}
              </v-chip>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="border-b border-gray-100 px-6 h-16"
                      ><h5>Excluir modelo</h5></v-card-title
                    >
                    <v-card-text class="p-4">
                      <div v-if="snackbar">
                        <v-alert type="success" dense>{{
                          snackbarText
                        }}</v-alert>
                      </div>
                      <p class="m-0 p-0 text-center" v-else>
                        Deseja excluir permanentemente este modelo?
                      </p>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn class="btn-secondary" text @click="closeDelete"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        v-if="!loading"
                        class="btn-primary"
                        text
                        @click="deleteItemConfirm"
                        :loading="loading"
                      >
                        OK
                      </v-btn>
                      <!-- <v-btn v-if="loading" class="btn-primary" text loading
                        >OK</v-btn
                      > -->
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-list-item @click="deleteItem(item)">
                <v-list-item-title>
                  <v-icon small> mdi-delete </v-icon>
                </v-list-item-title>
              </v-list-item>
              <!-- <v-menu offset-y left close-on-content-click>
                <template v-slot:activator="{ on }">
                  <v-btn fab x-small v-on="on">
                    <v-icon class="text-red-600"> mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <v-list dense class="p-0"> </v-list>
              </v-menu> -->
            </template>

            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize"> Redefinir </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <!-- <v-snackbar v-model="snackbar" :timeout="snackbarTimeout">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Fechar
          </v-btn>
        </template>
      </v-snackbar> -->
    </div>
  </div>
</template>

<script>
import SentimentModelService from "@/services/sentiment-model.service";
import { Helpers } from "@/Helpers.js";
export default {
  name: "SentimentModelManage",
  data() {
    return {
      defaultItem: {
        id: 0,
        measure: 0,
        word: "",
      },
      dialog: false,
      dialogDelete: false,
      disabled: false,
      editedItem: {
        word: "",
        measure: null,
        ratings: [],
      },
      editedIndex: -1,
      headers: [
        { text: "ID", value: "id" },
        { text: "Média", value: "measure" },
        { text: "Termo", value: "word" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      loading: false,
      message: "",
      newResource: {
        word: "",
        measure: null,
        ratings: [0],
      },
      newResourceWordRules: [(v) => !!v || "Informar um termo é requerido"],
      sliders: [],
      snackbar: false,
      snackbarText: "",
      snackbarTimeout: 4000,
      valid: true,
      words: [],
      disableAddRating: false,
      disableCreateWord: false,
      iframe: JSON.parse(this.$route.query.iframe ?? false),
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.loading = true;
      SentimentModelService.getWordsByUser()
        .then(
          (response) => {
            this.loading = false;
            this.words = response.data;
          },
          (error) => {
            Helpers.verifyTokenError(this, error, this.$route.path);

            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.response.data.detail ||
              error.toString();
          }
        )
        .catch((error) => {
          console.log("erro", error);
        });
    },
    deleteItem(item) {
      this.editedIndex = this.words.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
      this.snackbar = false;
      this.snackbarText = "";
    },

    deleteItemConfirm() {
      this.loading = true;
      SentimentModelService.delete(this.editedItem).then(
        () => {
          this.words.splice(this.editedIndex, 1);
          this.snackbarText = "Termo deletado com sucesso!";
          this.snackbar = true;
          setTimeout(() => {
            this.closeDelete();
          }, 2000);
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.response.data.detail ||
            error.toString();
        }
      );
      this.loading = false;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.resetNewResource();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        SentimentModelService.create(this.newResource).then(
          (response) => {
            this.words.push(response.data);
            this.snackbarText = "Termo cadastrado com sucesso!";
            this.snackbar = true;
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.response.data.detail ||
              error.toString();
          }
        );
        setTimeout(() => {
          this.loading = false;
          this.snackbar = false;
          this.snackbarText = "";
          this.close();
        }, 2000);
      }
    },

    resetNewResource() {
      this.newResource = {
        word: "",
        measure: null,
        ratings: [0],
      };
    },

    goToSentimentModel() {
      this.$router.push({ name: "sentiment" });
    },

    removeRating(index) {
      this.newResource.ratings.splice(index, 1);
      if (this.newResource.ratings.length <= 10) {
        this.disableAddRating = false;
      }
      if (this.newResource.ratings.length <= 0) {
        this.disableCreateWord = true;
      }
    },

    returnHintByIndex(index) {
      return `Avaliação ${index + 1} (${this.newResource.ratings[index]})`;
    },

    addRating() {
      this.newResource.ratings.push(0);
      if (this.newResource.ratings.length >= 10) {
        this.disableAddRating = true;
      }
      if (
        this.newResource.ratings.length > 0 &&
        this.newResource.ratings.length <= 10
      ) {
        this.disableCreateWord = false;
      }
    },

    formatNumber(value) {
      return value.toFixed(2);
    },
  },
};
</script>
