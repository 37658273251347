import Vue from 'vue'
import axios from 'axios'
import authHeader from "@/services/auth-header"

const httpAdmin = axios.create({
    baseURL: process.env.VUE_APP_API_ADMIN_URL,
    headers: {
        'Content-Type': 'application/json',
        accept: '*/*'
    }
})

const httpModels = axios.create({
    baseURL: process.env.VUE_APP_API_MODEL_URL,
    headers: {
        'Content-Type': 'application/json',
        accept: '*/*'
    }
})

const request = {
    success: req => {
        req.headers.Authorization = authHeader()
        return req
    },
    error: error => {
        return Promise.reject(error)
    }
}

httpAdmin.interceptors.request.use(request.success, request.error)
httpModels.interceptors.request.use(request.success, request.error)

const response = {
    success: res => {
        return res
    },
    error: error => {
        return Promise.reject(error)
    }
}

httpAdmin.interceptors.response.use(response.success, response.error)
httpModels.interceptors.response.use(response.success, response.error)

Vue.prototype.$httpAdmin = httpAdmin
Vue.prototype.$httpModels = httpModels
Vue.prototype.$axios = axios

export { httpAdmin, httpModels };