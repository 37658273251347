import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Login.vue';
import Home from '@/views/Home.vue';
import SentimentModel from "@/views/SentimentModel";
import IntentModel from "@/views/IntentModel";
import SentimentModelManage from "@/views/SentimentModelManage";
import AdminUserManage from "@/views/admin/AdminUserManage";

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        redirect: '/',
    },
    {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            loggedOutLayout: true,
            text: "Login"
        },
    },
    {
        path: '/home',
        name: 'home',
        component: Home
    },
    {
        path: "/sentiment-analysis",
        name: "sentiment",
        component: SentimentModel,
    },
    {
        path: "/sentiment-analysis/manage",
        name: "sentiment.manage",
        component: SentimentModelManage,
    },
    {
        path: "/intent-recognition",
        name: "intent",
        component: IntentModel,
    },
    {
        path: "/admin/users",
        name: "admin.users",
        component: AdminUserManage,
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
})


router.beforeEach((to, from, next) => {
    const publicPages = ['/'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    if (authRequired && !loggedIn) {
        next('/');
    } else {
        next();
    }
});

export default router
