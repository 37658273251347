<template>
  <v-app v-if="!$route.meta.loggedOutLayout">
    <v-main class="main-wrapper">
      <TheAppHeader v-show="!iframe" />
      <MainMenu :currentUser="currentUser" />

      <slot />
    </v-main>
    <TheAppFooter v-show="!iframe" />
  </v-app>
</template>


<script>
import Eventbus from "@/Eventbus.js";
import MainMenu from "@/components/General/MainMenu.vue";
import TheAppHeader from "@/components/General/TheAppHeader.vue";
import TheAppFooter from "@/components/General/TheAppFooter.vue";

export default {
  components: { MainMenu, TheAppHeader, TheAppFooter },
  data() {
    return {
      sidebarIsOpen: false,
      modalContent: "",
      iframe: JSON.parse(this.$route.query.iframe ?? false),
    };
  },
  created() {
    Eventbus.$on("open-sidebar", (modalContent) => {
      this.modalContent = modalContent;
      this.sidebarIsOpen = true;
    });
    Eventbus.$on("close-sidebar", () => {
      this.sidebarIsOpen = false;
      this.modalContent = "";
    });
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style scoped>
.logo {
  max-width: 120px;
}
.menu-item {
  padding-left: 40px !important;
}
</style>

