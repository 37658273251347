<template>
  <div class="h-full px-8 py-20 bg-gray-200 logged-in-page">
    <div class="p-6 container-fluid">
      <h2 class="mb-8">Gerenciar usuários</h2>
      <v-card foat>
        <v-card-title class="h-16 px-6 border-b border-gray-100">
          Usuários
          <v-spacer></v-spacer>
          <v-btn
            small
            @click="goToHome()"
            class="mr-3 btn-secondary"
            v-if="!iframe"
          >
            Início
          </v-btn>
          <v-btn small @click="addUser" class="btn-primary">
            <v-icon class="mr-1 text-sm">mdi-plus</v-icon>
            Novo usuário
          </v-btn>
        </v-card-title>

        <v-divider class="mx-4"></v-divider>

        <v-alert v-if="message" type="error" dismissible>
          {{ message }}
        </v-alert>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="users"
            :loading="loading"
            sort-by="id"
            flat
          >
            <template v-slot:[`item.is_active`]="{ item }">
              <v-chip :color="getColor(item.is_active)" dark>
                {{ item.is_active ? "Sim" : "Não" }}
              </v-chip>
            </template>
            <template v-slot:[`item.is_admin`]="{ item }">
              <v-chip :color="getColor(item.is_admin)" dark>
                {{ item.is_admin ? "Sim" : "Não" }}
              </v-chip>
            </template>

            <template v-slot:[`item.modules`]="{ item }">
              <v-chip
                class="mr-3 bg-yellow-200"
                v-for="permission in item.permissions"
                :key="permission.name"
              >
                <strong>{{ permission.name }}</strong>
              </v-chip>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-menu offset-y left close-on-content-click>
                <template v-slot:activator="{ on }">
                  <v-btn fab x-small v-on="on">
                    <v-icon class="text-red-600"> mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <v-list dense class="p-0">
                  <v-list-item @click="editItem(item)">
                    <v-list-item-title>
                      <v-icon small class="mr-2 text-sm text-red-600">
                        mdi-pencil
                      </v-icon>
                      Editar usuário</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="deleteItem(item)">
                    <v-list-item-title>
                      <v-icon small class="mr-2 text-sm text-red-600">
                        mdi-delete
                      </v-icon>
                      Remover usuário</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize"> Redefinir </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <v-dialog v-if="dialog" persistent v-model="dialog" max-width="500px">
        <!-- <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="error"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Novo Usuário
                    </v-btn>
                  </template> -->
        <v-card flat>
          <v-card-title class="h-16 px-6 mb-3 border-b border-gray-100">
            <h5>{{ formTitle }}</h5>
          </v-card-title>

          <v-card-text>
            <div class="pb-0" v-if="snackbar">
              <v-alert type="success" dense>{{ snackbarText }}</v-alert>
            </div>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <label for="username">Usuário</label>
                  <v-text-field
                    id="username"
                    v-model="editedItem.username"
                    :rules="usernameRules"
                    placeholder="Insira o nome de usuário"
                    required
                    solo
                    flat
                    outlined
                    :disabled="!edit"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" v-if="edit">
                  <label for="password">Senha</label>
                  <v-text-field
                    id="password"
                    v-model="editedItem.password"
                    :rules="passwordRules"
                    placeholder="Insira a senha"
                    required
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="showPassword = !showPassword"
                    :type="showPassword ? 'text' : 'password'"
                    solo
                    flat
                    outlined
                    current-password
                  ></v-text-field>
                </v-col>

                <v-col v-if="!edit" cols="12">
                  <label for="isActive">Usuário ativo</label>
                  <v-select
                    id="isActive"
                    v-model="editedItem.is_active"
                    :rules="isActiveRules"
                    :items="itemsYesOrNo"
                    item-text="text"
                    item-value="value"
                    placeholder="Ativo"
                    persistent-hint
                    return-object
                    single-line
                    required
                    solo
                    flat
                    outlined
                  ></v-select>
                </v-col>

                <v-col cols="12" v-if="edit">
                  <label for="isAdmin"
                    >Adicionar permissão de administrador</label
                  >
                  <v-select
                    id="isAdmin"
                    v-model="editedItem.is_admin"
                    :rules="isAdminRules"
                    :items="itemsYesOrNo"
                    item-text="text"
                    item-value="value"
                    placeholder="Definir como administrador"
                    return-object
                    single-line
                    required
                    solo
                    flat
                    outlined
                  ></v-select>
                </v-col>

                <v-col cols="12" v-if="edit">
                  <label for="permissions">Permissões</label>
                  <v-select
                    id="permissions"
                    v-model="userPermissions"
                    :items="modules"
                    placeholder="Definir permissões de usuário"
                    multiple
                    chips
                    solo
                    flat
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions class="px-5 py-3 border-t border-gray-100">
            <v-spacer></v-spacer>
            <v-btn
              class="btn-secondary"
              text
              @click="close"
              :disabled="loading"
            >
              Cancelar
            </v-btn>
            <v-btn class="btn-primary" @click="save" v-if="!loading">
              Salvar
            </v-btn>
            <v-btn class="btn-primary" v-if="loading" loading> </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-if="dialogDelete" v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="h-16 px-6 border-b border-gray-100">
            <h5>Excluir usuário</h5></v-card-title
          >
          <v-card-text class="p-4">
            <div class="pb-0" v-if="snackbar">
              <v-alert type="success" dense>{{ snackbarText }}</v-alert>
            </div>
            <p class="mb-0 text-center">
              Deseja excluir permanentemente este usuário?
            </p>
          </v-card-text>
          <v-card-actions class="px-5 py-3 border-t border-gray-100">
            <v-spacer></v-spacer>
            <v-btn class="btn-secondary" @click="closeDelete">Cancelar</v-btn>
            <v-btn class="btn-primary" @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- <v-snackbar v-model="snackbar" :timeout="snackbarTimeout">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Fechar
          </v-btn>
        </template>
      </v-snackbar> -->
    </div>
  </div>
</template>

<script>
import UsersService from "@/services/users.service";
import ModulesService from "@/services/module.service";
import { Helpers } from "@/Helpers.js";

export default {
  name: "AdminUserManage",
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Usuário", value: "username" },
        { text: "Ativo", value: "is_active" },
        { text: "Administrador", value: "is_admin" },
        { text: "Permissões", value: "modules" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      users: [],
      editedIndex: -1,
      editedItem: {
        username: "",
        password: null,
        is_active: null,
        is_admin: null,
      },
      defaultItem: {
        username: "",
        password: null,
        is_active: null,
        is_admin: null,
      },
      message: "",
      disabled: false,
      snackbar: false,
      snackbarText: "",
      snackbarTimeout: 4000,
      valid: true,
      loading: false,
      select: { text: "Não", value: 0 },
      itemsYesOrNo: [
        { text: "Não", value: 0 },
        { text: "Sim", value: 1 },
      ],
      showPassword: false,
      passwordRules: [(v) => !!v || "A senha é requerida"],
      usernameRules: [(v) => !!v || "O nome de usuário é requerido"],
      isActiveRules: [(v) => !!v || "Informar se usuário é ativo é requerido"],
      isAdminRules: [
        (v) => !!v || "Informar se usuário é administrador é requerido",
      ],
      userPermissions: [],
      modules: [],
      currentUserId: null,
      iframe: JSON.parse(this.$route.query.iframe ?? false),
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Novo Usuário" : "Editar Usuário";
    },
    edit() {
      return this.editedIndex === -1;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.loading = true;
      ModulesService.getModules().then(
        (response) => {
          for (let [key, value] of Object.entries(
            Object.values(response.data)
          )) {
            console.log(key);
            this.modules.push(value.name);
          }
        },
        (error) => {
          Helpers.verifyTokenError(this, error, this.$route.path);
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.response.data.detail ||
            error.toString();
        }
      );
      UsersService.getUsers().then(
        (response) => {
          this.users = response.data;
        },
        (error) => {
          Helpers.verifyTokenError(this, error, this.$route.path);
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.response.data.detail ||
            error.toString();
        }
      );
      this.loading = false;
    },

    addUser() {
      this.snackbar = false;
      this.snackbarText = "";
      this.message = "";

      this.dialog = true;
    },

    editItem(item) {
      this.snackbar = false;
      this.snackbarText = "";
      this.message = "";
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.snackbar = false;
      this.snackbarText = "";
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loading = true;
      UsersService.delete(this.editedItem.id).then(
        () => {
          this.users.splice(this.editedIndex, 1);
          this.snackbarText =
            "Usuário " + this.editedItem.username + " deletado com sucesso!";
          this.snackbar = true;

          setTimeout(() => {
            this.closeDelete();
          }, 1000);
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.response.data.detail ||
            error.toString();
        }
      );
      this.loading = false;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.snackbar = false;
        this.snackbarText = "";
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.loading = true;
      let index = this.editedIndex;
      if (this.editedIndex > -1) {
        UsersService.update(this.editedItem).then(
          (response) => {
            this.snackbar = true;
            this.snackbarText =
              "Usuário " + response.data.username + " atualizado com sucesso!";
            Object.assign(this.users[index], response.data);
            setTimeout(() => {
              this.close();
            }, 1000);
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.response.data.detail ||
              error.toString();
          }
        );
      } else {
        UsersService.create(this.editedItem).then(
          (response) => {
            this.snackbar = true;
            this.snackbarText =
              "Usuário " + response.data.username + " cadastrado com sucesso!";
            this.users.push(response.data);
            this.currentUserId = response.data.id;

            UsersService.setPermissions(
              this.currentUserId,
              this.userPermissions
            ).then(
              (response) => {
                const userToModify = this.users.findIndex((user) => {
                  return user.id === this.currentUserId;
                });

                for (let i = 0; i < response.data.permissions.length; i++) {
                  this.users[userToModify].permissions.push(
                    response.data.permissions[i]
                  );
                  this.userPermissions.push(response.data.permissions[i]);
                }

                this.resetCurrentUser();
              },
              (error) => {
                this.resetCurrentUser();
                this.message =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.response.data.detail ||
                  error.toString();
              }
            );
            setTimeout(() => {
              this.close();
            }, 1000);
          },
          (error) => {
            this.resetCurrentUser();
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.response.data.detail ||
              error.toString();
          }
        );
      }

      this.loading = false;
    },

    goToHome() {
      this.$router.push({ name: "home" });
    },

    getColor(value) {
      if (value) {
        return "green";
      }
      return "red";
    },

    resetCurrentUser() {
      this.userPermissions = [];
      this.currentUserId = null;
    },
  },
};
</script>
