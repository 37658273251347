import {httpModels} from "@/config/axios.config";

class SentimentModelService {
    getScore(text) {
        return httpModels
            .post('sentiment/score', {text})
            .then(response => {
                return response;
            });
    }

    create(resource) {
        return httpModels
            .post('sentiment/lexicon/', {
                word: resource.word,
                ratings: resource.ratings
            })
            .then(response => {
                return response;
            });
    }

    getWordsByUser() {
        return httpModels
            .get('sentiment/lexicon/?limit=1000000')
            .then(response => {
                return response;
            });
    }

    delete(item) {
        return httpModels
            .delete('sentiment/lexicon/' + item.id)
            .then(response => {
                return response;
            });
    }
}

export default new SentimentModelService();