<template>
  <v-btn class="btn-secondary" @click="closeSidebar"> Cancelar </v-btn>
</template>

<script>
import Eventbus from "@/Eventbus";
export default {
  methods: {
    closeSidebar() {
      Eventbus.$emit("close-sidebar");
    },
  },
};
</script>