import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import router from './router'
import store from './store'

import "./assets/css/global.css";
import "tailwindcss/tailwind.css";

Vue.config.productionTip = false

import BtnCloseSidebar from "@/components/General/BtnCloseSidebar";
import BtnAddItem from "@/components/General/BtnAddItem";
import MensagemListaVazia from "@/components/General/MensagemListaVazia";
import Loader from "@/components/General/Loader.vue";
Vue.component("btn-close-sidebar", BtnCloseSidebar);
Vue.component("btn-add-item", BtnAddItem);
Vue.component("no-content-message", MensagemListaVazia);
Vue.component("loader", Loader);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')




