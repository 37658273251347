<template>
  <div class="h-full px-8 py-20 bg-gray-100 logged-in-page">
    <div class="p-6 container-fluid">
      <h2 class="mb-8">Reconhecimento de Intenção</h2>

      <v-form ref="form" v-model="valid">
        <v-card elevation="2" :loading="loading">
          <template slot="progress">
            <v-progress-linear
              color="red"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>

          <v-card-title class="border-b border-gray-100 px-6 h-16">
            <h5>Analisar modelos de intenção</h5>
            <v-spacer></v-spacer>
            <v-btn
              small
              class="btn-secondary"
              @click="goToHome()"
              :disabled="disabled"
              v-if="!iframe"
            >
              Início
            </v-btn>
          </v-card-title>

          <v-container>
            <div v-if="responseAPI.text != null">
              <v-alert border="top" color="orange" dark flat>
                <v-card-title class="flex justify-center">
                  <h3 class="text-h5 text-center">
                    Texto: {{ responseAPI.text }}
                  </h3>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <p>
                    <strong>Intenções:</strong>
                  </p>
                  <p>{{ showIntent(responseAPI.intents) }}</p>
                </v-card-text>
              </v-alert>
            </div>
          </v-container>

          <v-card-text class="py-4 px-6">
            <v-row>
              <v-col cols="12">
                <label for="searchTerm"
                  >Insira o termo que será analisado</label
                >
                <v-textarea
                  id="searchTerm"
                  v-model="text"
                  :rules="textRules"
                  auto-grow
                  flat
                  outlined
                  rows="10"
                  row-height="15"
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="px-6 py-3 border-t border-gray-100">
            <v-spacer></v-spacer>
            <v-btn
              v-if="responseAPI.text !== null"
              @click="clearText"
              :disabled="disabled"
              class="btn-secondary"
            >
              limpar
            </v-btn>
            <v-btn
              v-if="!loading"
              :disabled="!valid"
              @click="consultIntentModel()"
              class="btn-primary"
              type="button"
            >
              Enviar
            </v-btn>
            <v-btn
              v-if="loading"
              color="error"
              class="btn-secondary>"
              type="button"
              elevation="2"
              loading
            >
            </v-btn>
          </v-card-actions>

          <v-alert v-if="message" type="error" dismissible>
            {{ message }}
          </v-alert>
        </v-card>
      </v-form>

      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ textSnackbar }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import ModelsEnums from "@/Enums/ModelsEnums";
import IntentModelService from "@/services/intent-model.service";
import { Helpers } from "@/Helpers.js";
export default {
  name: "IntentModel",
  props: {
    source: String,
  },
  data() {
    return {
      disabled: false,
      hasPermission: false,
      loading: false,
      message: "",
      models: ModelsEnums,
      snackbar: false,
      text: "",
      textSnackbar: "Você não tem permissão para realizar essa ação.",
      timeout: 4000,
      textRules: [(v) => !!v || "Informar algum termo é requerido"],
      valid: false,
      responseAPI: { text: null, intents: [] },
      iframe: JSON.parse(this.$route.query.iframe ?? false),
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    consultIntentModel() {
      if (this.$refs.form.validate()) {
        this.screenFetchingDataFromAPI(true);
        IntentModelService.getIntent(this.text)
          .then(
            (response) => {
              this.responseAPI.text = response.data.text;
              this.responseAPI.intents = response.data.intents;
              this.screenFetchingDataFromAPI(false);
            },
            (error) => {
              Helpers.verifyTokenError(this, error, this.$route.path);
              this.screenFetchingDataFromAPI(false);
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.response.data.detail ||
                error.toString();
            }
          )
          .catch((error) => {
            console.log("erro", error);
          });
      }
    },
    screenFetchingDataFromAPI(bool) {
      this.loading = bool;
      this.disabled = bool;
    },
    clearText() {
      this.text = null;
      this.responseAPI.text = null;
      this.$refs.form.resetValidation();
    },
    goToHome() {
      this.$router.push({ name: "home" });
    },
    showIntent(intents) {
      return intents.length === 0
        ? "Nenhuma intenção foi detectada"
        : intents.join(", ");
    },
  },
};
</script>
