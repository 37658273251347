<template>
  <v-navigation-drawer fixed temporary v-model="menuIsOpen">
    <header
      class="flex justify-between p-3 navigation-drawer-header align-center"
    >
      <img src="@/assets/images/logo-wittel.png" alt="" class="logo" />

      <v-app-bar-nav-icon small @click="closeMenu" class="text-gray-800">
        <v-icon small> mdi-close </v-icon>
      </v-app-bar-nav-icon>
    </header>
    <v-divider class="my-0 mb-5 border-gray-400 border-b-1"></v-divider>

    <v-list nav dense>
      <router-link to="/home" class="rounded-md">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-red-600">
              <v-icon class="mr-2 text-red-600" small>mdi-home</v-icon>
              Início
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link to="/sentiment-analysis" class="rounded-md">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-red-600">
              <v-icon class="mr-2 text-red-600" small>mdi-tag-heart</v-icon>
              Análise de Sentimentos
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link to="/intent-recognition" class="rounded-md">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-red-600">
              <v-icon class="mr-2 text-red-600" small
                >mdi-thought-bubble</v-icon
              >
              Reconhecimento de Intenção
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link to="/admin/users" class="text-red-600">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-red-600">
              <v-icon class="mr-2 text-red-600" small
                >mdi-account-circle</v-icon
              >
              Gerenciar usuários
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>
    </v-list>
  </v-navigation-drawer>
</template>


<script>
import Eventbus from "@/Eventbus.js";
export default {
  name: "MainMenu",
  props: ["currentUser"],
  data() {
    return {
      menuIsOpen: false,
    };
  },
  methods: {
    closeMenu() {
      this.menuIsOpen = false;
    },
  },
  created() {
    Eventbus.$on("open-menu", (data) => {
      this.menuIsOpen = data;
    });
  },
};
</script>


<style scoped>
.logo {
  max-width: 120px;
}

.v-list--nav .v-list-item.menu-item {
  padding-left: 42px;
}
</style>
