<template>
  <div class="h-full px-8 py-20 bg-gray-100 logged-in-page">
    <div class="p-6 container-fluid">
      <h2 class="page-title mb-8">
        Olá, {{ currentUser.user.username }}. Boas-vindas ao seus Modelos.
      </h2>
      <v-card>
        <v-card-title class="border-b border-gray-100 px-6 h-16">
          <h5>Selecione qual das opções deseja acessar:</h5>
        </v-card-title>

        <v-card-text class="p-4 py-8 flex justify-center">
          <div class="max-w-4xl justify-center flex">
            <v-row class="justify-center mx-auto">
              <v-col v-for="card in cards" :key="card.title">
                <v-card
                  height="100%"
                  max-width="400"
                  class="cursor-pointer mx-auto"
                  @click="goToModel(card.url)"
                >
                  <v-img
                    :src="card.src"
                    class="white--text align-end"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    min-height="230"
                  >
                  </v-img>
                  <v-card-title
                    v-text="card.title"
                    class="text-center justify-center"
                  ></v-card-title>
                  <v-card-subtitle class="text-center">{{
                    card.subtitle
                  }}</v-card-subtitle>

                  <v-card-text
                    class="text--primary text-center flex flex-1 flex-column"
                    v-text="card.description"
                  >
                  </v-card-text>

                  <!-- <v-card-actions class="flex justify-center py-4">
                  <v-btn class="btn-primary" text @click="goToModel(card.url)">
                    Acessar
                  </v-btn>
                </v-card-actions> -->
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>

      <v-container fluid> </v-container>

      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Fechar
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import ModelsEnums from "@/Enums/ModelsEnums";

export default {
  name: "Home",
  data: () => ({
    snackbar: false,
    hasPermission: false,
    text: "Você não tem permissão para acessar esse modelo.",
    timeout: 4000,
    models: ModelsEnums,
    cards: [
      {
        title: "Analisador de Sentimentos",
        subtitle: "Sentiment analysis",
        description:
          "Analisa um texto e classifica-o de acordo com o sentimento.",
        src: "./images/analise-sentimentos.png",
        url: ModelsEnums.SENTIMENT,
        flex: 4,
      },
      {
        title: "Reconhecimento de Intenção",
        subtitle: "Intent Recognition",
        description: "Extrai a intenção representada no texto",
        src: "./images/analise-intencoes.png",
        url: ModelsEnums.INTENT,
        flex: 4,
      },
    ],
  }),
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    goToModel(modelName) {
      this.hasPermission = this.checkIfUserHasPermission(
        this.currentUser.user.permissions,
        modelName
      );
      if (this.hasPermission) {
        this.$router.push({ name: modelName }).catch(() => {});
      } else {
        this.snackbar = true;
      }
    },

    checkIfUserHasPermission(permissions, permission) {
      return Object.keys(permissions).some(function (key) {
        return permissions[key].name === permission;
      });
    },
  },
};
</script>
