export const Helpers = {
    scrollDown(element) {
        element.scrollTop = element.scrollHeight;
    },
    localhostProxy(url) {
        const proxyURL = process.env.NODE_ENV === "development"
            ? `https://cors-anywhere.herokuapp.com/` + url
            : url;
        return proxyURL;
    },
    verifyTokenError(context, error, redirectPath) {

        let isTokenError =
            (error.response.status >= 400) && (error.response.data.detail.indexOf("inválido") !== -1);

        if (isTokenError) {
            localStorage.removeItem("user");
            context.$store.dispatch("logout");
            context.$router.push("/login");
            sessionStorage.setItem("redirectPath", redirectPath);
        }
    }
} 