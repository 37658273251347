<template>
  <div class="h-full px-8 py-20 bg-gray-100 logged-in-page">
    <div class="p-6 container-fluid">
      <h2 class="mb-8">Criar Modelos de Sentimento</h2>
      <v-card flat :loading="loading">
        <template slot="progress">
          <v-progress-linear
            color="red"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-card-title class="border-b border-gray-100 px-6 h-16">
          <h5>Modelo de sentimento</h5>
          <v-spacer></v-spacer>
          <v-btn
            class="btn-secondary mr-3"
            @click="goToHome()"
            :disabled="disabled"
            small
            v-if="!iframe"
          >
            Início
          </v-btn>
          <v-btn
            class="btn-primary"
            small
            @click="goToManage()"
            :disabled="disabled"
            v-if="!iframe"
          >
            Gerenciar modelos
          </v-btn>
        </v-card-title>

        <v-card-text class="py-4 px-6">
          <div v-if="description !== ''">
            <v-alert border="top" :color="alertColor" dark flat>
              <v-card-title class="flex justify-center">
                <h3 class="text-h5 text-center">
                  {{ description }} ({{ compound }})
                </h3>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <p>
                  O score de sentimento varia entre -1 e +1. Quanto mais próximo
                  de -1, mais negativo é o sentimento e vice-versa. Caso o score
                  de sentimento caia em uma região entre -0.05 e +0.05, dizemos
                  que a frase apresenta um sentimento neutro.
                </p>
              </v-card-text>
            </v-alert>
          </div>

          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12">
                <label for="term">Digite o termo que será analisado</label>
                <v-textarea
                  id="term"
                  v-model="text"
                  :rules="textRules"
                  auto-grow
                  rows="10"
                  row-height="15"
                  required
                  outlined
                  flat
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 py-3 border-t border-gray-100">
          <v-spacer></v-spacer>
          <v-btn
            class="btn-secondary"
            @click="clearText"
            :disabled="disabled"
            v-if="description !== ''"
          >
            Reiniciar
          </v-btn>
          <v-btn
            v-if="loading"
            color="error"
            class="btn-primary"
            type="button"
            loading
          >
          </v-btn>
          <v-btn
            v-if="!loading"
            :disabled="!valid"
            @click="consultSentimentModel()"
            class="btn-primary"
            type="button"
          >
            Enviar
          </v-btn>
        </v-card-actions>

        <v-alert v-if="message" type="error" dismissible>
          {{ message }}
        </v-alert>
      </v-card>
    </div>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ textSnackbar }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ModelsEnums from "@/Enums/ModelsEnums";
import SentimentModelService from "@/services/sentiment-model.service";
import { Helpers } from "@/Helpers.js";
export default {
  name: "SentimentModel",
  props: {
    source: String,
  },
  data() {
    return {
      alertColor: "",
      compound: null,
      description: "",
      disabled: false,
      hasPermission: false,
      loading: false,
      message: "",
      models: ModelsEnums,
      snackbar: false,
      text: "",
      textSnackbar: "Você não tem permissão para realizar essa ação.",
      timeout: 4000,
      textRules: [(v) => !!v || "Informar algum termo é requerido"],
      valid: false,
      iframe: JSON.parse(this.$route.query.iframe ?? false),
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    consultSentimentModel() {
      if (this.$refs.form.validate()) {
        this.screenFetchingDataFromAPI(true);
        SentimentModelService.getScore(this.text).then(
          (response) => {
            this.compound = response.data.compound;
            this.updateResultOnScreen();
            this.screenFetchingDataFromAPI(false);
          },
          (error) => {
            Helpers.verifyTokenError(this, error, this.$route.path);
            this.screenFetchingDataFromAPI(false);
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.response.data.detail ||
              error.toString();
          }
        );
      }
    },
    updateResultOnScreen() {
      let compound = this.compound;
      let compoundIsInvalid = compound == null || compound === "";
      let compoundIsPositive = compound > 0.05;
      let compoundIsNegative = compound < -0.05;

      if (compoundIsInvalid) {
        this.setAttribute();
      } else if (compoundIsPositive) {
        this.setResultAttributes("Sentimento Positivo", "green lighten-2");
      } else if (compoundIsNegative) {
        this.setResultAttributes("Sentimento Negativo", "red lighten-2");
      } else {
        this.setResultAttributes("Sentimento Neutro", "grey");
      }
    },
    setResultAttributes(
      description = "Análise indisponível",
      alertColor = "alert alert-info"
    ) {
      this.description = description;
      this.alertColor = alertColor;
    },
    screenFetchingDataFromAPI(bool) {
      this.loading = bool;
      this.disabled = bool;
    },
    clearText() {
      this.text = "";
      this.compound = null;
      this.description = "";
      this.$refs.form.resetValidation();
    },
    goToHome() {
      this.$router.push({ name: "home" });
    },
    goToManage() {
      this.$router.push({ name: "sentiment.manage" });
    },
  },
};
</script>
