<template>
  <v-app-bar v-if="currentUser" fixed class="bg-white shadow-md" flat>
    <v-app-bar-nav-icon
      small
      @click="handleClickMenu"
      class="text-gray-800 mr-4 ml-1"
    ></v-app-bar-nav-icon>
    <img src="@/assets/images/logo-wittel.png" alt="" class="logo" />
    <h1 class="pl-3 ml-5 text-xl text-gray-900 border-l app-title">
      Análise de Sentimentos
    </h1>

    <v-spacer></v-spacer>

    <v-menu bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" @click="logOut">
          <v-icon class="text-gray-800">mdi-logout</v-icon>
        </v-btn>
      </template>
    </v-menu>
  </v-app-bar>
</template>

<script>
import Eventbus from "@/Eventbus.js";
export default {
  methods: {
    handleClickMenu() {
      Eventbus.$emit("open-menu", true);
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style scoped>
.logo {
  max-width: 120px;
}
</style>