import { httpModels } from "@/config/axios.config";

class AuthService {
    login(user) {
        return httpModels
            .post('auth/login', {
                username: user.username,
                password: user.password
            })
            .then(response => {

                if (response.data.token) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }
                return response.data;
            })
    }

    logout() {
        localStorage.removeItem('user');
    }
}

export default new AuthService();
