<template>
  <div
    class="flex justify-center w-full h-full bg-red-900  align-center logged-out-page"
  >
    <div
      class="grid w-full p-8  lg:grid-cols-3 place-content-center max-w-screen-2xl logged-out-page-inner"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.logged-out-page {
  background-image: url("~@/assets/images/bg-login.jpg");
}
.v-application .error--text {
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
}

.theme--light.v-messages {
  color: #ff5252 !important;
}
</style>