<template>
  <DefaultLayout v-if="!$route.meta.loggedOutLayout">
    <router-view v-slot="{ Component }">
      <transition>
        <component :is="Component" />
      </transition>
    </router-view>
  </DefaultLayout>

  <router-view v-slot="{ Component }" v-else>
    <transition>
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import DefaultLayout from "@/layouts/Default.vue";

export default {
  components: {
    DefaultLayout,
  },
  data: () => ({
    drawer: false,
    group: null,
    fab: false,
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    timeoutId: null,
  }),
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },

    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },

    toTop() {
      this.$vuetify.goTo(0);
    },

    goToHome() {
      this.$router.push({ name: "home" });
    },

    goToUsers() {
      this.$router.push({ name: "admin.users" });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Merienda&display=swap");
body {
  background: #f8f8f8;
}

.logo {
  max-width: 120px;
  display: block;
  margin: 0 auto;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px !important;
}
</style>
