<template>
  <v-btn
    icon
    small
    class="text-white transition duration-500 bg-red-600 hover:bg-red-800"
  >
    <v-icon class="text-sm">mdi-plus</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "BtnAddItem",
};
</script>