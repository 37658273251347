import { httpAdmin } from "@/config/axios.config";

class UsersService {
    getUsers() {
        return httpAdmin
            .get('users/?limit=1000000')
            .then(response => {
                return response;
            });
    }

    create(resource) {
        return httpAdmin
            .post('users/', {
                username: resource.username,
                password: resource.password,
                is_admin: resource.is_admin.value
            })
            .then(response => {
                return response;
            });
    }

    update(resource) {
        return httpAdmin
            .patch('users/' + resource.id, { is_active: resource.is_active.value })
            .then(response => {
                return response;
            });
    }

    delete(userId) {
        return httpAdmin
            .delete('users/' + userId)
            .then(response => {
                return response;
            });
    }

    setPermissions(userId, permissions) {
        return httpAdmin
            .post(`users/${userId}/permissions`, {
                modules: permissions,
            })
            .then(response => {
                return response;
            });
    }
}

export default new UsersService();