<template>
  <div class="loader-wrapper flex items-center justify-center flex-column p-10">
    <div class="loader w-16 h-16 relative">
      <div
        class="
          absolute
          left-0
          top-0
          w-full
          h-full
          border-gray-300 border-solid border-8
          rounded-full
        "
      ></div>
      <div
        class="
          absolute
          left-0
          top-0
          w-full
          h-full
          border-solid border-8
          rounded-full
          animate-spin
          duration-75
          spinner
        "
      ></div>
    </div>
    <p class="text-gray-600 mt-2 mb-0 font-bold">Carregando...</p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.spinner {
  border-color: red transparent transparent transparent;
}
</style>