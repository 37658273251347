<template>
  <v-footer v-if="currentUser" padless fixed class="bg-white">
    <v-col class="text-center" cols="12">
      {{ new Date().getFullYear() }} —
      <strong>Wittel</strong>
      — &reg; Todos os direitos reservados
    </v-col>
  </v-footer>
</template>

<script>
export default {
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>
