import { httpModels } from "@/config/axios.config";

class IntentModelService {
    getIntent(text) {
        return httpModels
            .post('intent/', { text })
            .then(response => {
                return response;
            });
    }
}

export default new IntentModelService();