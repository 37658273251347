import { httpAdmin } from "@/config/axios.config";

class ModulesService {
    getModules() {
        return httpAdmin
            .get('modules/?limit=1000000')
            .then(response => {
                return response;
            });
    }
}

export default new ModulesService();